// SPDX-FileCopyrightText: 2024 Universität Göttingen
//
// SPDX-License-Identifier: EUPL-1.2
import React from "react";
import { Grid, Typography } from "@mui/material";
import MainCard from "../../components/MainCard";
import StatisticWidget from "../../components/cards/statistics/StatisticWidget";
import RecentAnnotations from "../../components/dashboard/RecentAnnotations";
import { AnnotationsCount } from "../../api/getters";
import { PropertiesCount } from "../../api/getters";
import { ObjectsCount } from "../../api/getters";
import { useEffect, useState } from "react";

export default function MainDashboard() {
  const [annotationCount, setAnnotationCount] = useState(0);
  const [propertyCount, setPropertyCount] = useState(0);
  const [objectCount, setObjectCount] = useState(0);

  useEffect(() => {
    const fetchAnnotationCount = async () => {
      const AnnotationCount = await AnnotationsCount();
      setAnnotationCount(AnnotationCount);
      const PropertyCount = await PropertiesCount();
      setPropertyCount(PropertyCount);
      const ObjectCount = await ObjectsCount();
      setObjectCount(ObjectCount);
    };

    fetchAnnotationCount();
  }, []);

  return (
    <Grid container rowSpacing={4.5} columnSpacing={2.75}>
      {/* row 1 */}
      <Grid item xs={12} sx={{ mb: -2.25 }}>
        <Typography variant="h5">Dashboard</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={4}>
        <StatisticWidget title="Total Annotations" count={annotationCount} />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={4}>
        <StatisticWidget title="Total Properties" count={propertyCount} />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={4}>
        <StatisticWidget title="Total Objects" count={objectCount} />
      </Grid>

      <Grid
        item
        md={8}
        sx={{ display: { sm: "none", md: "block", lg: "none" } }}
      />

      {/* row 2 */}
      <Grid item xs={12} md={7} lg={8}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h5">Recent Annotations</Typography>
          </Grid>
          <Grid item />
        </Grid>
        <MainCard sx={{ mt: 2 }} content={false}>
          <RecentAnnotations />
        </MainCard>
      </Grid>
      <Grid item xs={12} md={5} lg={4}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h5">Annotations Report</Typography>
          </Grid>
          <Grid item />
        </Grid>
        <MainCard sx={{ mt: 2 }} content={false}>
          {/* <AnnotationsReport /> */}
        </MainCard>
      </Grid>
    </Grid>
  );
}
