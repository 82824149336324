// SPDX-FileCopyrightText: 2024 Universität Göttingen
//
// SPDX-License-Identifier: EUPL-1.2

import React, { useState } from 'react';
import { Container, Typography, Box } from '@mui/material';

import Annotations from '../../components/rosen-shapes/Annotations';

const AnnotationsTable: React.FC = () => {
    const [refresh, setRefresh] = useState(false);

    return (
        <Container>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="h4" gutterBottom>
                    Annotations
                </Typography>
            </Box>
            <Annotations refresh={refresh} />
        </Container>
    );
};

export default AnnotationsTable;

