// SPDX-FileCopyrightText: 2024 Universität Göttingen
//
// SPDX-License-Identifier: EUPL-1.2

import { FetchData } from "../api/FetchData";
import {
  AnnotationsCountQuery,
  PropertiesCountQuery,
  ObjectsCountQuery,
} from "../data/Queries";
import { AxiosResponse } from "axios";

const AnnotationsCount = async () => {
  const Request = {
    query: AnnotationsCountQuery,
    headers: {
      "Content-Type": "application/sparql-query",
    },
    url: `${process.env.REACT_APP_API_SPARQL}`,
  };

  try {
    const response = await FetchData(Request);

    if ((response as AxiosResponse).data) {
      const axiosResponse = response as AxiosResponse;
      return parseInt(
        axiosResponse.data.results.bindings[0].annotationCount.value,
        10
      );
    }

    console.error("Unexpected response structure:", response);
    return 0;
  } catch (error) {
    console.error("Error fetching data:", error);
    return 0;
  }
};

const PropertiesCount = async () => {
  const Request = {
    query: PropertiesCountQuery,
    headers: {
      "Content-Type": "application/sparql-query",
    },
    url: `${process.env.REACT_APP_API_SPARQL}`,
  };
  try {
    const response = await FetchData(Request);

    if ((response as AxiosResponse).data) {
      const axiosResponse = response as AxiosResponse;
      return parseInt(
        axiosResponse?.data.results.bindings[0].predicateCount.value,
        10
      );
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return 0;
  }
};

const ObjectsCount = async () => {
  const Request = {
    query: ObjectsCountQuery,
    headers: {
      "Content-Type": "application/sparql-query",
    },
    url: `${process.env.REACT_APP_API_SPARQL}`,
  };
  try {
    const response = await FetchData(Request);

    if ((response as AxiosResponse).data) {
      const axiosResponse = response as AxiosResponse;
      return parseInt(
        axiosResponse?.data.results.bindings[0].objectCount.value,
        10
      );
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return 0;
  }
};

export { AnnotationsCount, PropertiesCount, ObjectsCount };
