// SPDX-FileCopyrightText: 2024 Universität Göttingen
//
// SPDX-License-Identifier: EUPL-1.2

import React, { useState } from 'react';
import { Container, Typography, Box } from '@mui/material';
import ResearchLiteraturePassages from '../../components/rosen-shapes/ResearchLiteraturePassages';
import { GenerateResourceQuad } from '../../utils/helpers';
import AddResource from '../../utils/AddResource';

const ResearchLiteraturePassage: React.FC = () => {
    const [refresh, setRefresh] = useState(false);
    const getNewInitialQuads = async () => {
        return await GenerateResourceQuad('sord:passage', 'sor:ResearchLiteraturePassage');
    };

    const refreshAuthors = () => {
        setRefresh(prev => !prev);
    };

    return (
        <Container>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="h4" gutterBottom>
                    Research Literature Passage
                </Typography>
                <AddResource getNewInitialQuads={getNewInitialQuads} onSaveComplete={refreshAuthors} buttonLabel="Add Research Literature Passage" />
            </Box>
            <ResearchLiteraturePassages refresh={refresh} />
        </Container>
    );
};
export default ResearchLiteraturePassage;

