// SPDX-FileCopyrightText: 2022 Georg-August-Universität Göttingen
// SPDX-FileCopyrightText: 2024 Universität Göttingen
//
// SPDX-License-Identifier: EUPL-1.2
// SPDX-License-Identifier: EUPL-1.2

import { DeleteQuery, DeleteWorkQuery } from "../data/Queries";
import { AxiosResponse } from "axios";
import axios from "axios";
import Prefixes from "../data/Prefixes";
import { v4 as uuidv4 } from "uuid";
import { FetchData as Fetcher } from "../api/FetchData";

export const DeleteAnnotation = async (query: string) => {
  const Request = {
    query: query,
    headers: {
      "Content-Type": "application/sparql-update",
    },
    url: `${process.env.REACT_APP_PROD_API_STATEMENTS}`,
  };
  const response = await Fetcher(Request);
  console.log("result", response);
  return response;
};

export const DeleteResource = async (uri: string) => {
  try {
    const subject = uri;
    const url = `${process.env.REACT_APP_API_STATEMENTS}`;
    const deleteQuery = DeleteQuery(subject);
    const headers = {
      "Content-Type": "application/sparql-update",
    };
    await axios.post(url, deleteQuery, { headers });
    return true;
  } catch (err) {
    console.log("Error deleting row");
    return false;
  }
};

export const DeleteEdition = async (query: string) => {
  console.log("query", query);
  const Request = {
    query: query,
    headers: {
      "Content-Type": "application/sparql-update",
    },
    url: `${process.env.REACT_APP_PROD_API_STATEMENTS}`,
  };
  const response = await Fetcher(Request);
  console.log("result", response);
  return response;
};

export const DeleteWork = async (uri: string) => {
  try {
    const subject = uri;
    console.log("subject", subject);
    const url = `${process.env.REACT_APP_API_STATEMENTS}`;
    const deleteQuery = DeleteWorkQuery(subject);
    console.log("deleteQuery", deleteQuery);
    const headers = {
      "Content-Type": "application/sparql-update",
    };
    await axios.post(url, deleteQuery, { headers });
    return true;
  } catch (err) {
    console.log("Error deleting row");
    return false;
  }
};

export const GenerateResourceQuad = async (
  ns: string,
  resourceName: string
) => {
  const uniqueId = uuidv4();
  return `
    ${Prefixes}
    ${ns}_${uniqueId} a ${resourceName};`;
};

export const FetchData = async (query: string) => {
  const Request = {
    query: query,
    headers: {
      "Content-Type": "application/sparql-query",
    },
    url: `${process.env.REACT_APP_API_SPARQL}`,
  };
  const result = await Fetcher(Request);
  if ((result as AxiosResponse).data) {
    const axiosResponse = result as AxiosResponse;
    const bindings = axiosResponse?.data.results.bindings;
    return bindings;
  }
};
