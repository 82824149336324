// SPDX-FileCopyrightText: 2024 Universität Göttingen
//
// SPDX-License-Identifier: EUPL-1.2

import axios from "axios";
import Prefixes from "../data/Prefixes";

type Request = {
  query: string;
  headers: Record<string, string>;
  url: string;
};

export const FetchData = async (Request: Request) => {
  try {
    const query = `
        ${Prefixes}
        ${Request.query}
        `;
    const headers = Request.headers;
    const response = await axios.post(Request.url, query, { headers });
    return response;
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};
