// SPDX-FileCopyrightText: 2024 Universität Göttingen
//
// SPDX-License-Identifier: EUPL-1.2

import { lazy } from "react";

// project import
//import Loadable from "../components/Loadable";
import Dashboard from "../layout/Dashboard";
import TeiText from "@fe/roger-frames";
import AnnotationsTable from "../pages/Annotations/index";
import PersonTable from "../pages/Persons/index";
import SourcePassageTable from "../pages/SourcePassages/index";
import EgotextPassageTable from "../pages/Egotexts/index";
import ResearchLiteraturePassageTable from "../pages/ResearchLiteraturePassages/index";
import Editions from "../pages/Editions/index";
import WorkTable from "../pages/Works/index";
import InstitutionTable from "../pages/Institutions/index";
import IsbnTable from "../pages/ISBN/index";
import PlaceTable from "../pages/Places/index";
import PublisherTable from "../pages/Publishers/index";
import MainDashboard from "../pages/Dashboard/index";
import { useAppContext } from "../context/AppContext";

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = () => {
  const { config } = useAppContext();
  return {
    path: "/",
    element: <Dashboard />,
    children: [
      {
        path: "/dashboard",
        element: <MainDashboard />,
      },
      {
        path: "/",
        children: [
          {
            path: "/",
            element: <MainDashboard />,
          },
        ],
      },
      {
        path: "annotate",
        element: <TeiText config={config} />,
      },
      {
        path: "searchannotation",
        element: <AnnotationsTable />,
      },
      {
        path: "searchperson",
        element: <PersonTable />,
      },
      {
        path: "searcsource",
        element: <SourcePassageTable />,
      },
      {
        path: "searchegotext",
        element: <EgotextPassageTable />,
      },
      {
        path: "searchresearch",
        element: <ResearchLiteraturePassageTable />,
      },
      {
        path: "searchinstance",
        element: <Editions />,
      },
      {
        path: "searchwork",
        element: <WorkTable />,
      },
      {
        path: "searchinstitution",
        element: <InstitutionTable />,
      },
      {
        path: "searchisbn",
        element: <IsbnTable />,
      },
      {
        path: "searchplace",
        element: <PlaceTable />,
      },
      {
        path: "searchpublisher",
        element: <PublisherTable />,
      },
    ],
  };
};

export default MainRoutes;
