// SPDX-FileCopyrightText: 2022 Georg-August-Universität Göttingen
// SPDX-FileCopyrightText: 2024 Universität Göttingen
//
// SPDX-License-Identifier: EUPL-1.2
// SPDX-License-Identifier: EUPL-1.2

import * as React from "react";
import Stack from "@mui/material/Stack";
import { Roger } from "@fe/roger-core";
import type { Quad } from "@rdfjs/types";
import type { SparqlConfig } from "../sparql/update";
import { updateResource } from "../sparql/update";
import { useSnackbar } from "../hooks/useSnackbar";

interface RogerFrameProps {
  schema: string;
  config: SparqlConfig;
  initialQuads: string;
  endpoints: {};
}

export default function RogerFrame({
  schema,
  config,
  initialQuads,
  endpoints,
}: RogerFrameProps) {
  const [changed, setChanged] = React.useState<boolean>(false);
  const { showSnackbar, SnackbarComponent } = useSnackbar();
  async function handleSave(quads: Quad[]) {
    const response = await updateResource(quads, config);
    if (response?.success) {
      showSnackbar(response.message, "success");
    } else {
      showSnackbar("Failed to add annotation!", "success");
    }
  }

  return (
    <Stack direction="row" spacing={2}>
      <Roger
        schema={schema}
        data={initialQuads}
        onSave={config ? handleSave : undefined}
        useChanged={[changed, setChanged]}
        endpoints={endpoints}
      />
      {SnackbarComponent}
    </Stack>
  );
}
