// SPDX-FileCopyrightText: 2024 Universität Göttingen
//
// SPDX-License-Identifier: EUPL-1.2
import React from "react";
import { Box, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { AllAnnotations } from "../../data/Queries";
import { FetchData } from "../../utils/helpers";
import { Link } from "react-router-dom";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

export default function RecentAnnotations() {
  const [annotations, setAnnotations] = useState([]);

  const parser = (bindings) => {
    return bindings.map((binding, index) => ({
      id: index,
      annotation: binding.annotation.value,
      exact: binding.exact?.value ?? null,
      created: binding.created?.value ?? null,
    }));
  };

  const fetchData = async () => {
    const bindings = await FetchData(AllAnnotations);
    const annotations = parser(bindings);
    setAnnotations(annotations);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    { field: "exact", headerName: "Text", flex: 1 },
    { field: "created", headerName: "Created", flex: 1 },
  ];

  return (
    <Box
      sx={{
        height: 400,
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {annotations.length != 0 ? (
        <DataGrid
          rows={annotations}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5]}
          checkboxSelection
          disableRowSelectionOnClick
        />
      ) : (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          mt={4}
        >
          <Link to="/annotate">
            <AddCircleOutlineIcon sx={{ color: "gray", fontSize: 32 }} />{" "}
            {/* White icon and size */}
          </Link>
          <Typography variant="body1" my={2}>
            No annotations added yet, Add new!
          </Typography>
        </Box>
      )}
    </Box>
  );
}
