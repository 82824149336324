// SPDX-FileCopyrightText: 2022 Georg-August-Universität Göttingen
// SPDX-FileCopyrightText: 2024 Universität Göttingen
//
// SPDX-License-Identifier: EUPL-1.2
// SPDX-License-Identifier: EUPL-1.2

import React, { useEffect, useState, useRef } from "react";
import { Box, Grid, Container, Typography, Button } from "@mui/material";
import type { SparqlConfig } from "../sparql/update";
import RogerFrame from "./RogerFrame";
import TeiText from "../data/Text";
import rdfData from "../data/rdfData";
import Schema from "../data/Schema";
import ParsedXML from "./ParsedXml";
interface TeiXmlProps {
  config: SparqlConfig;
}

export default function TeiXml({ config }: TeiXmlProps) {
  const xml = TeiText;
  const schemaRef = useRef<HTMLDivElement | null>(null);

  interface AnnotationData {
    selectedText?: string;
    dataInfo?: string;
    startOffset?: number;
    endOffset?: number;
    targetId?: string;
  }
  const [schemaStore, setSchema] = useState("");
  const [annotationData, setAnnotationData] = useState("");
  const [endData, setEndData] = useState<AnnotationData>({});
  const [combinedData, setCombinedData] = useState<{
    startData: AnnotationData;
    endData: AnnotationData;
  }>({
    startData: { dataInfo: "", targetId: "" },
    endData: { dataInfo: "", targetId: "" },
  });
  const [startData, setStartData] = useState<AnnotationData>({});
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [leftGridSize, setLeftGridSize] = useState(0);
  const [rightGridSize, setRightGridSize] = useState(12);

  useEffect(() => {
    if (!startData.dataInfo || !endData.dataInfo) return;
    const newCombinedData = {
      startData: { ...startData },
      endData: { ...endData },
    };
    setSchema(Schema);
    setCombinedData(newCombinedData);
    setAnnotationData(rdfData(combinedData));
    setCombinedData(newCombinedData);
  }, [startData, endData]);

  const handleSelection = () => {
    if (!window.getSelection) return;

    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      const selectedText = selection.toString().trim();
      if (selectedText) {
        const range = selection.getRangeAt(0);
        const startNode = range.startContainer.parentNode as HTMLElement;
        const endNode = range.endContainer.parentNode as HTMLElement;

        if (
          schemaRef.current?.contains(startNode) &&
          schemaRef.current?.contains(endNode)
        ) {
          const selectionStartData: AnnotationData = {
            dataInfo: decodeURIComponent(
              startNode.getAttribute("sameas") || ""
            ),
            startOffset: range.startOffset,
            targetId: startNode.getAttribute("id") || "",
          };

          const handleMouseUp = () => {
            const selectedTextWithoutNewlines = range
              .toString()
              .replace(/\n/g, "");

            const selectionEndData: AnnotationData = {
              selectedText: selectedTextWithoutNewlines,
              dataInfo: decodeURIComponent(
                endNode.getAttribute("sameas") || ""
              ),
              endOffset: range.endOffset,
              targetId: endNode.getAttribute("id") || "",
            };

            const newCombinedData = {
              startData: selectionStartData,
              endData: selectionEndData,
            };

            const newAnnotationData = rdfData(newCombinedData);

            setStartData(selectionStartData);
            setEndData(selectionEndData);
            setAnnotationData(newAnnotationData);
            setCombinedData(newCombinedData);

            setLeftGridSize(6);
            setRightGridSize(6);
            setDrawerOpen(true);

            document.removeEventListener("mouseup", handleMouseUp);
          };

          document.addEventListener("mouseup", handleMouseUp);
        }
      }
    }
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setLeftGridSize(0);
    setRightGridSize(12);
  };

  const endpoints = {
    endpoint: `${process.env.REACT_APP_API_SPARQL}`,
    update: `${process.env.REACT_APP_API_STATEMENTS}`,
  };

  return (
    <>
      <Container maxWidth="xl" style={{ padding: "2rem" }}>
        <Grid container spacing={3}>
          <Grid
            item
            xs={rightGridSize}
            sx={{
              backgroundColor: "white",
              padding: "1rem",
              borderRadius: "8px",
              border: "1px solid #80808070",
            }}
          >
            <div
              ref={schemaRef}
              onMouseUp={handleSelection}
              dangerouslySetInnerHTML={{ __html: xml }}
              style={{ height: "100%", overflowY: "auto" }}
            />
            {/* <ParsedXML xml={xml} /> */}
            <ParsedXML xml={xml} />
          </Grid>

          {leftGridSize !== 0 && (
            <Grid
              item
              xs={leftGridSize}
              sx={{
                position: "sticky",
                top: "2rem",
                height: "calc(100vh - 2rem)",
                overflowY: "auto",
                backgroundColor: "white",
                padding: "1rem",
                borderRadius: "8px",
                border: "1px solid #80808070",
              }}
            >
              {drawerOpen ? (
                <Box sx={{ p: 2 }}>
                  <Button
                    onClick={handleDrawerClose}
                    variant="outlined"
                    margin-left={2}
                    sx={{
                      cursor: "pointer",
                      padding: "0.3rem 0.5rem",
                      margin: "0rem 0rem 0rem 1.5rem",
                      borderRadius: "8px",
                      transition: "background-color 0.3s",
                      "&:hover": {
                        backgroundColor: "primary",
                      },
                      my: 1.5,
                    }}
                  >
                    Close
                  </Button>
                  <RogerFrame
                    initialQuads={annotationData}
                    schema={schemaStore}
                    config={config}
                    endpoints={endpoints}
                  />
                </Box>
              ) : (
                <Typography variant="body1">
                  Select a text to annotate
                </Typography>
              )}
            </Grid>
          )}
        </Grid>
      </Container>
    </>
  );
}
