// SPDX-FileCopyrightText: 2024 Universität Göttingen
//
// SPDX-License-Identifier: EUPL-1.2

import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import SingleFrame from "./SingleFrame";
import Schema from "../data/Schema";
import { useAppContext } from "../context/AppContext";

interface AddResourceProps {
  getNewInitialQuads: () => Promise<string>;
  onSaveComplete: () => void;
  buttonLabel: string;
}

const AddResource: React.FC<AddResourceProps> = ({
  getNewInitialQuads,
  onSaveComplete,
  buttonLabel,
}) => {
  const { endpoints, config } = useAppContext();
  const [openDialog, setOpenDialog] = useState(false);
  const [initialQuads, setInitialQuads] = useState<string>("");

  const handleDialogOpen = async () => {
    const newInitialQuads = await getNewInitialQuads();
    setInitialQuads(newInitialQuads);
    setOpenDialog(true);
  };

  const handleDialogClose = () => setOpenDialog(false);

  const handleSave = async (quads) => {
    await onSaveComplete();
    handleDialogClose();
  };

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        aria-labelledby="edit-dialog-title"
      >
        <DialogContent>
          <SingleFrame
            schema={Schema}
            initialQuads={initialQuads}
            endpoints={endpoints}
            config={config}
            onSave={handleSave}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <Button variant="contained" onClick={handleDialogOpen}>
        {buttonLabel}
      </Button>
    </>
  );
};

export default AddResource;
