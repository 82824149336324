// SPDX-FileCopyrightText: 2024 Universität Göttingen
//
// SPDX-License-Identifier: EUPL-1.2
import React from 'react';
import { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

export const useConfirmDialog = () => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState('');
    const [onConfirm, setOnConfirm] = useState<() => void>(() => { });

    const showConfirmDialog = (content: string, onConfirmAction: () => void) => {
        setDialogContent(content);
        setOnConfirm(() => onConfirmAction);
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const ConfirmDialogComponent = (
        <Dialog
            open={dialogOpen}
            onClose={handleDialogClose}
            aria-labelledby="confirm-dialog-title"
        >
            <DialogTitle id="confirm-dialog-title">Confirm</DialogTitle>
            <DialogContent>
                <DialogContentText>{dialogContent}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDialogClose}>Cancel</Button>
                <Button
                    onClick={() => {
                        onConfirm();
                        handleDialogClose();
                    }}
                    color="error"
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );

    return { showConfirmDialog, ConfirmDialogComponent };
};
