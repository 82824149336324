// SPDX-FileCopyrightText: 2024 Universität Göttingen
//
// SPDX-License-Identifier: EUPL-1.2

import React, { useState, useEffect } from "react";
import { Container, Typography, Box } from "@mui/material";
import { GenerateResourceQuad } from "../../utils/helpers";
import AddResource from "../../utils/AddResource";
import Places from "../../components/rosen-shapes/Places";

const PlaceTable: React.FC = () => {
  const [refresh, setRefresh] = useState(false);
  const getNewInitialQuads = async () => {
    return await GenerateResourceQuad("bf:place", "bf:Place");
  };

  const refreshAuthors = () => {
    setRefresh((prev) => !prev);
  };

  return (
    <Container>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h4" gutterBottom>
          Places
        </Typography>
        <AddResource
          getNewInitialQuads={getNewInitialQuads}
          onSaveComplete={refreshAuthors}
          buttonLabel="Add Place"
        />
      </Box>
      <Places refresh={refresh} />
    </Container>
  );
};

export default PlaceTable;
