// SPDX-FileCopyrightText: 2024 Universität Göttingen
//
// SPDX-License-Identifier: EUPL-1.2
import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Box,
  IconButton,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { DataGrid } from "@mui/x-data-grid";
import {
  GetSingleAnnotation,
  AllAnnotations,
  DeleteAnnotationQuery,
} from "../../data/Queries";
import { AxiosResponse } from "axios";
import EditFrame from "../../utils/EditFrame";
import SingleFrame from "src/utils/SingleFrame";
import Schema from "../../data/Schema";
import { useSnackbar } from "../../hooks/useSnackbar";
import { useConfirmDialog } from "../../hooks/useConfirmDialog";
import { FetchData, DeleteAnnotation } from "../../utils/helpers";
import { FetchData as ttl } from "../../api/FetchData";
import { Link } from "react-router-dom";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useAppContext } from "../../context/AppContext";

interface Binding {
  annotation: { value: string };
  exact?: { value: string };
  created?: { value: string };
}

interface ParsedBinding {
  id: number;
  annotation: string;
  exact: string | null;
  created: string | null;
}

const parser = (bindings: Binding[]): ParsedBinding[] => {
  return bindings.map((binding, index) => ({
    id: index,
    annotation: binding.annotation.value,
    exact: binding.exact?.value ?? null,
    created: binding.created?.value ?? null,
  }));
};

const Annotations: React.FC<{ refresh: boolean }> = ({ refresh }) => {
  const { endpoints, config } = useAppContext();
  const [annotations, setAnnotations] = useState([]);
  const [editQuads, setEditQuads] = useState("empty");
  const [openDialog, setOpenDialog] = useState(false);
  const { showSnackbar, SnackbarComponent } = useSnackbar();
  const { showConfirmDialog, ConfirmDialogComponent } = useConfirmDialog();
  const handleDialogOpen = () => setOpenDialog(true);
  const handleDialogClose = () => setOpenDialog(false);

  const fetchData = async () => {
    const bindings = await FetchData(AllAnnotations);
    const annotations = parser(bindings);
    setAnnotations(annotations);
  };

  useEffect(() => {
    fetchData();
  }, [refresh]);

  const handleEdit = async (uri: string) => {
    try {
      const Request = {
        query: GetSingleAnnotation(uri),
        headers: {
          "Content-Type": "application/sparql-query",
        },
        url: `${process.env.REACT_APP_API_SPARQL}`,
      };
      const result = await ttl(Request);
      if ((result as AxiosResponse).data) {
        const axiosResponse = result as AxiosResponse;
        setEditQuads(axiosResponse.data);
        handleDialogOpen();
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleDelete = (uri: string) => {
    showConfirmDialog("Are you sure you want to delete this annotation?", () =>
      confirmDelete(uri)
    );
  };

  const confirmDelete = async (uri: string) => {
    const query = DeleteAnnotationQuery(uri);
    const response = await DeleteAnnotation(query);
    if ((response as AxiosResponse).status === 204) {
      showSnackbar("Annotation deleted successfully!", "success");
      fetchData();
    } else {
      showSnackbar("Failed to delete annotation", "error");
    }
  };

  const onSave = async () => {
    fetchData();
    handleDialogClose();
    showSnackbar("Resource updated successfully", "success");
  };

  const columns = [
    { field: "exact", headerName: "Text", flex: 1 },
    { field: "created", headerName: "Created", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => (
        <Box
          display="flex"
          justifyContent="start"
          alignItems="center"
          width="100%"
        >
          <IconButton
            color="primary"
            onClick={() => handleEdit(params.row.annotation)}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            color="error"
            onClick={() => handleDelete(params.row.annotation)}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <>
      <Box
        sx={{
          height: 400,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {annotations.length > 0 ? (
          <DataGrid
            rows={annotations}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5]}
            checkboxSelection
            disableRowSelectionOnClick
          />
        ) : (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            mt={4}
          >
            <Link to="/annotate">
              <AddCircleOutlineIcon sx={{ color: "gray", fontSize: 32 }} />{" "}
              {/* White icon and size */}
            </Link>
            <Typography variant="body1" my={2}>
              No annotations added yet, Add new!
            </Typography>
          </Box>
        )}
      </Box>
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        aria-labelledby="edit-dialog-title"
      >
        <DialogContent>
          <EditFrame
            schema={Schema}
            initialQuads={editQuads}
            endpoints={endpoints}
            config={config}
            onSave={onSave}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Close</Button>
        </DialogActions>
      </Dialog>
      {SnackbarComponent}
      {ConfirmDialogComponent}
    </>
  );
};

export default Annotations;
