// SPDX-FileCopyrightText: 2022 Georg-August-Universität Göttingen
//
// SPDX-License-Identifier: EUPL-1.2

import type { Quad } from "@rdfjs/types";
import N3 from "n3";
import * as RST from "rdf-string-ttl";
import axios from "axios";

export interface SparqlConfig {
  sparqlEndpoint: string;
  sparqlUpdateEndpoint: string;
}

function flattenQuads(quads: N3.Quad[]): N3.Quad[] {
  const flattenedQuads: N3.Quad[] = [];
  const quadSet = new Set<string>();

  const processQuad = (quad: N3.Quad): void => {
    const quadString = `${quad.subject.value}|${quad.predicate.value}|${quad.object.value}`;

    if (quadSet.has(quadString)) {
      return;
    }

    quadSet.add(quadString);

    if (Array.isArray((quad.object as any).value)) {
      const nestedQuads = (quad.object as any).value as N3.Quad[];
      const firstNestedQuad = nestedQuads[0];

      // Use N3.DataFactory to ensure compatibility with N3 types
      const subject: N3.Quad_Subject =
        quad.subject.termType === "NamedNode"
          ? N3.DataFactory.namedNode(quad.subject.value)
          : N3.DataFactory.blankNode(quad.subject.value);

      const linkingQuad: N3.Quad = N3.DataFactory.quad(
        subject,
        N3.DataFactory.namedNode(quad.predicate.value),
        N3.DataFactory.blankNode(firstNestedQuad.subject.value)
      );

      processQuad(linkingQuad);
      nestedQuads.forEach((nestedQuad) => processQuad(nestedQuad));
    } else {
      flattenedQuads.push(quad);
    }
  };

  quads.forEach((quad) => processQuad(quad));

  return flattenedQuads;
}

export async function addResource(
  quads: Quad[],
  config: SparqlConfig
): Promise<void> {
  if (quads.length) {
    console.log("query", quads);
    const quadsList: N3.Quad[] = flattenQuads(quads as N3.Quad[]);
    const stringifiedQuads: RST.IStringQuad[] = quadsList.map((q) =>
      RST.quadToStringQuad(q)
    );

    let query = "";
    for (const sq of stringifiedQuads) {
      query += `${sq.subject}  ${sq.predicate} ${sq.object} .\n`;
    }

    try {
      console.log("query", query);
      await axios.post(config.sparqlUpdateEndpoint, query, {
        headers: { "content-type": "text/turtle" },
      });
    } catch (error) {
      console.error("Error adding annotation:", error);
    }
  }
}

export async function updateResource(
  quads: Quad[],
  config: SparqlConfig
): Promise<void> {
  if (quads.length) {
    console.log("quads to update", quads);

    const quadsList: N3.Quad[] = flattenQuads(quads as N3.Quad[]);
    const stringifiedQuads: RST.IStringQuad[] = quadsList.map((q) =>
      RST.quadToStringQuad(q)
    );

    const subjectURI = stringifiedQuads[0].subject;

    let deleteInsertQuery = `DELETE { ${subjectURI} ?predicate ?object . }\n`;
    deleteInsertQuery += "INSERT { \n";
    for (const sq of stringifiedQuads) {
      const formattedObject =
        sq.object.startsWith("http") && !sq.object.includes('"')
          ? `<${sq.object}>`
          : sq.object;
      deleteInsertQuery += `${sq.subject} ${sq.predicate} ${formattedObject} .\n`;
    }
    deleteInsertQuery += `} WHERE { ${subjectURI} ?predicate ?object . }`;

    try {
      await axios.post(config.sparqlUpdateEndpoint, deleteInsertQuery, {
        headers: { "content-type": "application/sparql-update" },
      });
    } catch (error) {
      console.error("Error updating resource:", error);
    }
  }
}
