// SPDX-FileCopyrightText: 2022 Georg-August-Universität Göttingen
// SPDX-FileCopyrightText: 2024 Universität Göttingen
//
// SPDX-License-Identifier: EUPL-1.2
// SPDX-License-Identifier: EUPL-1.2

import React, { useEffect, useState } from "react";
import ContentTable from "./ContentTable";

interface Section {
  id: string;
  title: string;
  subsections: Subsection[];
}

interface Subsection {
  id: string;
  title: string;
}

interface ParsedXMLProps {
  xml: string;
}

const ParsedXML: React.FC<ParsedXMLProps> = ({ xml }) => {
  const [sections, setSections] = useState<Section[]>([]);

  useEffect(() => {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xml, "text/xml");

    const parsedSections: Section[] = [];
    const sectionNodes = xmlDoc.querySelectorAll("tei-div[type='section']");

    sectionNodes.forEach((sectionNode) => {
      const sectionId = sectionNode.getAttribute("id") || "";
      const sectionTitle =
        sectionNode.querySelector("tei-head")?.textContent?.trim() || "";

      const subsections: Subsection[] = [];
      const subsectionNodes = sectionNode.querySelectorAll(
        "tei-div[type='subsection']"
      );

      subsectionNodes.forEach((subsectionNode) => {
        const subsectionId = subsectionNode.getAttribute("id") || "";
        const subsectionTitle =
          subsectionNode.querySelector("tei-head")?.textContent?.trim() || "";
        subsections.push({ id: subsectionId, title: subsectionTitle });
      });

      parsedSections.push({
        id: sectionId,
        title: sectionTitle,
        subsections: subsections,
      });
    });

    setSections(parsedSections);
  }, [xml]);

  return <ContentTable parsedSections={sections} />;
};

export default ParsedXML;
